












import { defineComponent, computed } from '@vue/composition-api'
interface FilterItem {
  label: string
  shouldShow: boolean
  value: string | null | undefined
}
const configuredProps = {
  displayEmptyOrDefault: {
    type: Boolean,
    required: false,
    default: false,
  },
  recipientGroupsLabel: {
    type: String,
    required: false,
    default: 'recipients',
  },
  recipientGroupNames: {
    type: String,
    required: false,
    default: '',
  },
  divisionLabel: {
    type: String,
    required: false,
    default: 'divisions',
  },
  divisionNames: {
    type: String,
    required: false,
    default: '',
  },
  teamsLabel: {
    type: String,
    required: false,
    default: 'teams',
  },
  teamNames: {
    type: String,
    required: false,
    default: '',
  },
  paymentStatusLabel: {
    type: String,
    required: false,
    default: 'payment status',
  },
  paymentStatus: {
    type: String,
    required: false,
    default: 'All',
  },
  evalStatusLabel: {
    type: String,
    required: false,
    default: 'eval status',
  },
  evalStatus: {
    type: String,
    required: false,
    default: 'All',
  },
  coachTeamStatusLabel: {
    type: String,
    required: false,
    default: 'coach assignment',
  },
  coachTeamStatus: {
    type: String,
    required: false,
    default: 'All Approved',
  },
  programLabel: {
    type: String,
    required: false,
    default: 'program',
  },
  program: {
    type: String,
    required: false,
    default: 'All',
  },
  allRecipientsLabel: {
    type: String,
    required: false,
    default: 'recipients across <u>all</u> churches',
  },
  myChurchOnlyRecipientsLabel: {
    type: String,
    required: false,
    default: 'recipients from <u>my church only</u>',
  },
  displayLeagueContacts: {
    type: Boolean,
    required: false,
    default: false,
  },
  viewAllLeagueContacts: {
    type: Boolean,
    required: false,
    default: false,
  },
  gradesLabel: {
    type: String,
    required: false,
    default: 'grades',
  },
  startGrade: {
    type: String,
    required: false,
    default: 'K3',
  },
  endGrade: {
    type: String,
    required: false,
    default: '12th',
  },
  practiceNightLabel: {
    type: String,
    required: false,
    default: 'practice night',
  },
  practiceNight: {
    type: String,
    required: false,
    default: 'All',
  },
  headCoachOnly: {
    type: Boolean,
    required: false,
    default: false,
  },
  coachesLabel: {
    type: String,
    required: false,
    default: 'coaches',
  },
  displayCoaches: {
    type: Boolean,
    required: false,
    default: false,
  },
}
export default defineComponent({
  name: 'RecipientFilterList',
  props: { ...configuredProps },
  components: {},
  setup(props) {
    const filters = computed(() => {
      return [
        {
          label: props.recipientGroupsLabel,
          shouldShow: props.displayEmptyOrDefault || !emptyOrAny(props.recipientGroupNames),
          value: props.recipientGroupNames!,
        },
        {
          label: props.paymentStatusLabel,
          shouldShow:
            props.displayEmptyOrDefault ||
            !emptyOrAny(props.paymentStatus, [configuredProps.paymentStatus.default]),
          value: props.paymentStatus!,
        },
        {
          label: props.evalStatusLabel,
          shouldShow:
            props.displayEmptyOrDefault ||
            !emptyOrAny(props.evalStatus, [configuredProps.evalStatus.default]),
          value: props.evalStatus!,
        },
        {
          label: props.coachTeamStatusLabel,
          shouldShow:
            props.displayEmptyOrDefault ||
            !emptyOrAny(props.coachTeamStatus, [configuredProps.coachTeamStatus.default]),
          value: props.coachTeamStatus!,
        },
        {
          label: props.programLabel,
          shouldShow:
            props.displayEmptyOrDefault || !emptyOrAny(props.program, [configuredProps.program.default, '0']),
          value: props.program!,
        },
        {
          label:
            props.viewAllLeagueContacts === true
              ? props.allRecipientsLabel
              : props.myChurchOnlyRecipientsLabel,
          shouldShow: props.displayLeagueContacts,
          value: 'yes',
        },
        {
          label: props.gradesLabel,
          shouldShow:
            props.displayEmptyOrDefault ||
            !emptyOrAny(props.startGrade, [configuredProps.startGrade.default]) ||
            !emptyOrAny(props.endGrade, [configuredProps.endGrade.default]),
          value: `${props.startGrade} to ${props.endGrade}`,
        },
        {
          label: props.practiceNightLabel,
          shouldShow:
            props.displayEmptyOrDefault ||
            !emptyOrAny(props.practiceNight, [configuredProps.practiceNight.default]),
          value: props.practiceNight!,
        },
        {
          label: props.divisionLabel,
          shouldShow:
            props.displayEmptyOrDefault ||
            !emptyOrAny(props.divisionNames, [configuredProps.divisionNames.default]),
          value: props.divisionNames,
        },
        {
          label: props.teamsLabel,
          shouldShow: props.displayEmptyOrDefault || !emptyOrAny(props.teamNames),
          value: props.teamNames,
        },
        {
          label: props.coachesLabel,
          shouldShow: props.displayCoaches || props.headCoachOnly,
          value: props.headCoachOnly ? 'Head Coach Only' : 'All Coaches',
        },
      ].filter((f: FilterItem) => f.shouldShow) as FilterItem[]
    })
    const emptyOrAny = (val: any, any: any[] = []): boolean => {
      return (
        val === undefined ||
        val === null ||
        val === '' ||
        (val instanceof Array && val.length === 0) ||
        (any.some &&
          any.some((v) => {
            return v === val
          }))
      )
    }
    return { filters }
  },
})
